:root {
  --bs-primary: #1D721E;
  --bs-primary-rgb: 29, 114, 30;
  --bs-primary-tint-88: rgba(var(--bs-primary-rgb),0.10);
  --bs-primary-tint-40: rgba(var(--bs-primary-rgb),0.66);
  --bs-primary-shade-20: rgba(var(--bs-primary-rgb),0.80);
  --bs-success-rgb: var(--bs-primary-rgb);
  --bs-bg-opacity:1;
}

body {
  font-family: "DM Sans", sans-serif;
}

.logo-mini {
  display: none;
  width: 40px;
}

.sidebar-default .sidebar-list .navbar-nav .nav-item {
  padding-left: 0;
  margin: 0;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link {
  align-items: center;
  padding: 6px 16px;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link.active {
  background-color: transparent;
  box-shadow: none;
  color: #1D721E;
  font-weight: 500;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link:not(.disabled, .active, [aria-expanded=true]) {
  color: #424242;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link:not(.disabled)[aria-expanded=true] {
  background-color: rgba(29, 114, 30, 0.1019607843);
  box-shadow: none;
  color: #1d721e;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link:not(.disabled)[aria-expanded=true] + .sub-nav {
  background-color: rgba(29, 114, 30, 0.1019607843);
  padding-left: 28px;
  padding-bottom: 10px;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link:not(.disabled) span {
  margin-left: 10px;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link.static-item .default-icon {
  font-size: 15px;
  font-weight: 400;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link .item-name {
  font-size: 15px;
  transition: unset !important;
}
@media screen and (min-width: 1250px) and (max-width: 1440px) {
  .sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link .item-name {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1440px) {
  .sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link span {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1440px) {
  .sidebar-default .sidebar-list .navbar-nav .nav-item .nav-link svg {
    width: 18px;
    height: 18px;
  }
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .sub-nav .nav-link {
  color: #1d721e;
  padding: 2px 16px;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .sub-nav .nav-link:not(.disabled):hover:not(.active):not([aria-expanded=true]) {
  background-color: transparent;
}
.sidebar-default .sidebar-list .navbar-nav .nav-item .sub-nav .nav-link span {
  margin-left: 7px;
  font-size: 14px;
}
.sidebar-default .sidebar-body {
  padding-right: 0;
}
.sidebar-mini .logo-full {
  display: none;
}
.sidebar-mini .logo-mini {
  display: block;
}
.sidebar-mini .sidebar-list .navbar-nav .nav-item .nav-link {
  padding: 6px 10px;
}

.btn {
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  font-family: "DM Sans", sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn svg {
  display: block;
}
.btn.btn-xs {
  min-height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 15px;
}
.btn.btn-grey {
  background: #e0e0e0;
  border-color: #e0e0e0;
  color: #454545;
}
.btn.btn-grey:hover {
  background: #c7c7c7;
  border-color: #c7c7c7;
  color: #000;
}
.btn.btn-black {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.btn.btn-black:hover {
  background: #242424;
  border-color: #242424;
}
.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-icon .btn-inner svg,
.btn-icon svg {
  position: unset;
  width: 18px;
  height: 18px;
}
.btn-icon.btn-sm {
  width: 32px;
  height: 32px;
}

.card {
  border-radius: 10px;
}
.card-header .card-title,
.card-header .card h4 {
  font-size: 20px;
  font-weight: 500;
}
.card-header {
  padding: 30px;
}
.card-header + .card-body {
  padding-top: 17.1428571429px;
}
.card-body {
  padding: 30px;
}
.card-border {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 15px;
}
.card-height {
  min-height: calc(100% - 32px);
}
.card-xs {
  padding: 0;
}
.card-xs .card-body {
  padding: 15px;
}

.form-group .remarks-textarea {
  min-height: 46px;
  height: 46px;
}
.form-label {
  font-size: 14px;
  color: #999;
}
.form-custom-select {
  width: 100%;
  position: relative;
}
.form-custom-select::before {
  content: "";
  position: absolute;
  right: 20px;
  top: 14px;
  border: 2px solid #000000;
  width: 10px;
  height: 10px;
  border-left: 0;
  border-top: 0;
  transform: rotate(45deg);
  pointer-events: none;
}
.form-custom-select select {
  cursor: pointer;
}
.form-control {
  border-radius: 10px;
  min-height: 46px;
  color: #000;
  border-color: #e0e0e0;
}
.form-control:focus {
  color: #000;
  border-color: #1D721E;
  box-shadow: none;
  z-index: unset !important;
}
.form-action {
  margin-top: 25px;
}
.form-radio {
  display: inline-block;
  margin-right: 15px;
}
.form-radio:last-of-type {
  margin-right: 0;
}
.form-radio-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.form-radio-label .radio {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: #e0e0e0;
  border: 1px solid currentColor;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-radio-label .radio::before {
  background: currentColor;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: inherit;
  opacity: 0;
  transform: scale(0.5);
}
.form-radio-input {
  position: absolute;
  opacity: 0;
  z-index: -999;
}
.form-radio-input:checked + label .radio {
  border-color: rgb(var(--bs-primary-rgb));
  color: rgb(var(--bs-primary-rgb));
}
.form-radio-input:checked + label .radio::before {
  opacity: 1;
  transform: scale(1);
}
.form-check {
  display: inline-block;
  margin-right: 15px;
  padding-left: 0;
}
.form-check:last-of-type {
  margin-right: 0;
}
.form-check-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.form-check-label .checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  color: #e0e0e0;
  border: 1px solid currentColor;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-check-label .checkbox::before {
  content: "";
  display: block;
  width: 12.1212121212px;
  height: 7.2727272727px;
  border-radius: inherit;
  opacity: 0;
  transform: scale(0.5) rotate(-45deg);
  border-radius: 0;
  background: transparent;
  border-left: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  margin-top: -4px;
}
.form-check-input {
  position: absolute;
  opacity: 0;
  z-index: -999;
}
.form-check-input:checked + label .checkbox {
  border-color: rgb(var(--bs-primary-rgb));
  color: rgb(var(--bs-primary-rgb));
}
.form-check-input:checked + label .checkbox::before {
  opacity: 1;
  transform: scale(1) rotate(-45deg);
}
.form-switch {
  display: inline-block;
  margin-right: 15px;
  padding-left: 0;
}
.form-switch:last-of-type {
  margin-right: 0;
}
.form-switch-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease;
}
.form-switch-label .switch {
  transition: all 0.25s ease;
  width: 52px;
  height: 29.7142857143px;
  border-radius: 52px;
  color: #e0e0e0;
  border: 1px solid currentColor;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
}
.form-switch-label .switch::before {
  margin-left: 0;
  transition: all 0.25s ease;
  background: currentColor;
  content: "";
  display: block;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
  border-radius: inherit;
}
.form-switch-input {
  position: absolute;
  opacity: 0;
  z-index: -999;
}
.form-switch-input:checked + label .switch {
  border-color: rgb(var(--bs-primary-rgb));
  color: rgb(var(--bs-primary-rgb));
}
.form-switch-input:checked + label .switch::before {
  opacity: 1;
  margin-left: 20px;
}
.form-image-upload .profile-img-edit {
  width: auto;
  max-width: 180px;
}
.form-image-upload .profile-img-edit > .upload-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -999;
}
.form-image-upload .profile-img-edit .upload-icone {
  left: 50%;
  transform: translate(-50%, 0px);
}

.table-responsive {
  border-bottom: none !important;
}
.table-responsive .dataTables_wrapper .row .dataTables_length {
  padding-left: 20px;
}
.table-responsive .dataTables_wrapper .row .dataTables_filter {
  padding-right: 20px;
}
.table-responsive .dataTables_wrapper table tr.even td, .table-responsive .dataTables_wrapper table tr.odd td {
  color: #666666;
}
@media screen and (min-width: 1250px) and (max-width: 1440px) {
  .table-inner th {
    font-size: 14px;
    padding: 10px !important;
  }
  .table-inner td {
    font-size: 12px !important;
    padding: 15px !important;
    font-weight: 400 !important;
  }
  .table-inner .dataTables_length select {
    font-size: 14px !important;
    padding: 10px !important;
  }
  .table-inner .dataTables_info {
    font-size: 14px !important;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .table-inner .pagination li {
    font-size: 12px !important;
  }
}
.table.dataTable {
  margin-bottom: 0px !important;
}
.table.dataTable thead tr th {
  background-color: #1d721e;
  padding: 16px 20px;
  font-weight: bold;
  color: #ffffff;
}
.table.dataTable thead tr th:first-child, .table.dataTable thead tr th:last-child {
  border-radius: 0;
}
.table.dataTable tbody tr.even {
  background-color: #f9f9f9;
}
.table.dataTable tbody tr td {
  padding: 12px 20px;
  border: none;
}
.table.dataTable tbody tr td .btn {
  margin-left: 5px;
  padding: 0;
}
.table.dataTable tbody tr td .btn-delete {
  color: #c52323;
}
.table.dataTable tbody tr td .btn-edit {
  color: #d59c18;
}
.table.dataTable tbody tr td .btn-list {
  color: #1d721e;
}
.table.dataTable tbody tr td .btn-add {
  color: #f16a1b;
}
.table.dataTable tbody tr td .badge {
  color: #1d721e;
  font-size: 15px;
  font-weight: 300;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 0;
}
.nav-tabs .nav-item .nav-link {
  color: #000000;
  margin-bottom: -1px;
  font-size: 16px;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: transparent;
}
.nav-tabs .nav-item .nav-link:hover {
  color: var(--bs-primary);
}
.nav-tabs .nav-item .nav-link.active {
  background-color: #ffffff;
  box-shadow: none;
  color: var(--bs-primary);
  border-width: 1px 1px 0px 1px;
  border-radius: 0;
  border-style: solid;
  border-color: #dee2e6;
}

.tab-content {
  padding: 20px;
  color: #000;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #dee2e6;
  font-size: 16px;
}

.page-header {
  margin-bottom: 20px;
}
@media screen and (min-width: 1250px) and (max-width: 1440px) {
  .page-header .header-title .card-title {
    font-size: 20px;
  }
}
.page-actions a {
  font-size: 14px !important;
}

.main-banner {
  position: relative;
  z-index: 0;
  height: 100vh;
  max-height: 400px;
}
.main-banner > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}
.main-banner .main-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  padding: 20px 0px;
  height: 50%;
}
.main-banner .main-header .main-logo {
  max-width: 150px;
}
.main-banner .main-header .main-logo-link {
  display: inline-block;
}

.dataTables_length select {
  margin: 0px 5px;
}
.dataTables_wrapper table {
  margin-top: 0;
}
.dataTables_wrapper table thead tr th {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  color: #000000;
  text-transform: unset;
}
.dataTables_wrapper table thead tr th:first-child {
  border-radius: 5px 0 0 5px;
}
.dataTables_wrapper table thead tr th:last-child {
  border-radius: 0px 5px 5px 0px;
}
.dataTables_wrapper table tbody tr.even td, .dataTables_wrapper table tbody tr.odd td {
  color: #4f4f4f;
  font-size: 15px;
  font-weight: 300;
}
.dataTables_wrapper table thead tr, .dataTables_wrapper table tbody tr {
  background-color: transparent;
}
.dataTables_wrapper table thead tr th, .dataTables_wrapper table thead tr td, .dataTables_wrapper table tbody tr th, .dataTables_wrapper table tbody tr td {
  padding: 10px;
  box-shadow: unset !important;
}

body .scrollbar-track {
  background: rgba(var(--bs-primary-rgb), 0.1);
}
body .scrollbar-track-x {
  width: 6px;
}
body .scrollbar-track-y {
  width: 6px;
}
body .scrollbar-thumb {
  width: 6px;
  background: rgba(var(--bs-primary-rgb), 0.45);
}

.back-btn .btn {
  padding-left: 12px;
}
.back-btn-icon {
  margin-right: 3px;
}

.sign-bg {
  overflow: hidden;
  z-index: 0;
}
.sign-bg img {
  opacity: 0.25;
  margin-top: -100px;
  margin-left: -100px;
}

.login-content .navbar-brand {
  margin-bottom: 75px;
  justify-content: center;
}
.login-content .navbar-brand img {
  max-width: 150px;
  width: 100%;
}
.login-content .card-transparent {
  background: transparent;
}

.request-account {
  position: relative;
  z-index: 1;
  margin-top: -150px;
}
.request-account .heading {
  font-size: 36px;
  margin-bottom: 15px;
  font-weight: 700;
}
.request-account .card {
  padding: 35px 45px;
  border-radius: 20px;
  box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
}
.request-account .border-right {
  border-right: 1px solid #e0e0e0;
}
.request-account .form-section {
  padding: 55px;
  padding-left: 30px;
}
.request-account .document-section {
  padding: 55px;
  padding-right: 30px;
}
.request-account .document-section h5 {
  font-size: 16px;
  color: #515B52;
}
.request-account .document-section ul {
  margin-top: 35px;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.request-account .document-section ul li {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
  font-size: 18px;
}
.request-account .document-section ul li:last-of-type {
  margin-bottom: 0;
}
.request-account .document-section ul li .box {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin-right: 15px;
  background-color: var(--bs-primary);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.request-account .document-section ul li .box svg {
  width: 20px;
  height: 20px;
  margin-top: 1px;
}
.request-account .document-section ul li p {
  flex: 1 1;
  margin-bottom: 0;
}

.dashboard-card .card-body {
  display: flex;
  flex-direction: column;
}
.dashboard-card .card-body .counter {
  font-size: 40px;
  font-weight: 400;
  margin: 0 0 10px;
  line-height: 38px;
  color: #303030;
}
.dashboard-card .card-body h5 {
  font-size: 19px;
  color: #808080;
  font-weight: 300;
  margin-bottom: 5px;
}
.dashboard-card .card-body small {
  margin-bottom: 20px;
}
.dashboard-card .card-body .table-responsive {
  font-size: 12px;
}
.dashboard-btn {
  margin-top: auto;
}

.organization-tabs {
  margin-bottom: 40px;
}