.dashboard{
	&-card{
		.card-body{
			display: flex;
			flex-direction: column;
			.counter{
			    font-size: 40px;
			    font-weight: 400;
			    margin: 0 0 10px;
			    line-height: 38px;
			    color: #303030;
			}
			h5{
				font-size: 19px;
    			color: #808080;
    			font-weight: 300;
    			margin-bottom: 5px;
			}
			small{
				margin-bottom: 20px;
			}
			.table-responsive{
				font-size: 12px;
			}
			
		}
	}
	&-btn{
		margin-top: auto;
	}
}
