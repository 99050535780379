@use "sass:map";
@use "sass:math";

.form {
	&-group {
		.remarks-textarea{
			min-height: 46px;
			height: 46px;
		}
	}

	&-label {
		font-size: 14px;
		color: #999;
	}

	&-custom {
		&-select {
			width: 100%;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				right: 20px;
				top: 14px;
				border: 2px solid #000000;
				width: 10px;
				height: 10px;
				border-left: 0;
				border-top: 0;
				transform: rotate(45deg);
				pointer-events: none
			}

			select {
				cursor: pointer;
			}
		}
	}

	&-control {
		border-radius: 10px;
		min-height: 46px;
		color: #000;
		border-color: #e0e0e0;

		&:focus {
			color: #000;
			border-color: #1D721E;
			box-shadow: none;
			z-index: unset !important;
		}
	}

	&-action {
		margin-top: 25px;
	}

	&-radio {
		display: inline-block;
		margin-right: 15px;

		&:last-of-type {
			margin-right: 0;
		}

		&-label {
			display: inline-flex;
			align-items: center;
			cursor: pointer;

			.radio {
				$radio-size: 20px;
				width: $radio-size;
				height: $radio-size;
				border-radius: 20px;
				color: #e0e0e0;
				border: 1px solid currentColor;
				margin-right: 10px;
				display: flex;
				align-items: center;
				justify-content: center;

				&::before {
					background: currentColor;
					content: "";
					display: block;
					width: math.div($radio-size, 2);
					height: math.div($radio-size, 2);
					border-radius: inherit;
					opacity: 0;
					transform: scale(0.5);
				}
			}
		}

		&-input {
			position: absolute;
			opacity: 0;
			z-index: -999;

			&:checked {
				&+label {
					.radio {
						border-color: rgb(var(--bs-primary-rgb));
						color: rgb(var(--bs-primary-rgb));

						&::before {
							opacity: 1;
							transform: scale(1);
						}
					}
				}
			}

		}
	}

	&-check {
		display: inline-block;
		margin-right: 15px;
		padding-left: 0;

		&:last-of-type {
			margin-right: 0;
		}

		&-label {
			display: inline-flex;
			align-items: center;
			cursor: pointer;

			.checkbox {
				$radio-size: 20px;
				width: $radio-size;
				height: $radio-size;
				border-radius: 5px;
				color: #e0e0e0;
				border: 1px solid currentColor;
				margin-right: 10px;
				display: flex;
				align-items: center;
				justify-content: center;

				&::before {
					content: "";
					display: block;
					width: math.div($radio-size, 1.65);
					height: math.div($radio-size, 2.75);
					border-radius: inherit;
					opacity: 0;
					transform: scale(0.5) rotate(-45deg);
					border-radius: 0;
					background: transparent;
					border-left: 1px solid currentColor;
					border-bottom: 1px solid currentColor;
					margin-top: -4px;
				}
			}
		}

		&-input {
			position: absolute;
			opacity: 0;
			z-index: -999;

			&:checked {
				&+label {
					.checkbox {
						border-color: rgb(var(--bs-primary-rgb));
						color: rgb(var(--bs-primary-rgb));

						&::before {
							opacity: 1;
							transform: scale(1) rotate(-45deg);
						}
					}
				}
			}
		}
	}

	&-switch {
		display: inline-block;
		margin-right: 15px;
		padding-left: 0;
		$switch-size: 52px;

		&:last-of-type {
			margin-right: 0;
		}

		&-label {
			display: inline-flex;
			align-items: center;
			cursor: pointer;
			transition: all 0.25s ease;

			.switch {
				transition: all 0.25s ease;

				width: $switch-size;
				height: math.div($switch-size, 1.75);
				border-radius: $switch-size;
				color: #e0e0e0;
				border: 1px solid currentColor;
				margin-right: 10px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 4px;

				&::before {
					margin-left: 0;
					transition: all 0.25s ease;
					background: currentColor;
					content: "";
					display: block;
					flex: 0 0 $switch-size / 2 - 4;
					width: $switch-size / 2 - 4;
					height: $switch-size / 2 - 4;
					border-radius: inherit;
				}
			}
		}

		&-input {
			position: absolute;
			opacity: 0;
			z-index: -999;

			&:checked {
				&+label {
					.switch {
						border-color: rgb(var(--bs-primary-rgb));
						color: rgb(var(--bs-primary-rgb));

						&::before {
							opacity: 1;
							margin-left: $switch-size - $switch-size / 2 - 6;
						}
					}
				}
			}

		}
	}

	&-image-upload {
		.profile-img-edit {
			width: auto;
			max-width: 180px;

			&>.upload-button {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				z-index: -999;
			}

			.upload-icone {
				left: 50%;
				transform: translate(-50%, 0px);
			}
		}
	}
}