.page{
	&-header{
		margin-bottom: 20px;

		.header{
			&-title{
				.card{
					&-title{
						@media screen and (min-width:1250px) and (max-width:1440px) {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
	&-actions{
		a{
			font-size: 14px !important;
		}
	}
}