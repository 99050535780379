.sidebar {
	&-default &{
		&-list{
			.navbar-nav {
				.nav{
					&-item {
						padding-left: 0;
						margin:0;
						.nav{
							&-link{
								align-items: center;
								padding:6px 16px;
								&.active{
								  background-color: transparent;
								  box-shadow: none;
								  color: $green;
								  font-weight: 500;
								}
								&:not(.disabled, .active, [aria-expanded=true] ){
									color: #424242;
								}
								&:not(.disabled)[aria-expanded=true]{
									background-color: #1d721e1a;
									box-shadow: none;
									color: #1d721e;
									& + .sub-nav{
										background-color: #1d721e1a;
										padding-left: 28px;
										padding-bottom: 10px;
									}
								}
								&:not(.disabled) span{
									margin-left: 10px;
								}
								&.static-item {
									.default-icon{
										font-size: 15px;
										font-weight: 400;
									}
							
								}
								.item-name{
									font-size: 15px;
									transition: unset !important;
									@media screen and (min-width:1250px) and (max-width:1440px) {
										font-size: 13px !important;
									}
								}
								span{
									@media screen and (min-width:1250px) and (max-width:1440px) {
										font-size: 13px !important;
									}
								}
								svg{
									@media screen and (min-width:1250px) and (max-width:1440px) {
										width: 18px;
										height: 18px;
									}
								}
							}
						}
						.sub{
							&-nav{
							   .nav{
							   	 &-link{
							   	 	color: #1d721e;
							   	 	padding: 2px 16px;
							   	 	&:not(.disabled):hover:not(.active):not([aria-expanded=true]){
							   	 		background-color: transparent;
							   	 	}
							   	 	span{
							   	 	  margin-left: 7px;
							   	 	  font-size: 14px;
							   	 	}
							   	 }
							   }
							}
						}
					}
				}
			}
		}
		&-body{
			padding-right: 0;
		}
	}
	&-mini{
		.logo{
			&-full{
				display: none;
			}
			&-mini{
				display: block;
			}
		}
	}
	&-mini &{
		&-list{
			.navbar-nav{
				.nav-item{
					.nav-link{
						padding: 6px 10px;
					}
				}
			}
		}
	}
}