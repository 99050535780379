.main-banner{
	position: relative;
	z-index: 0;
	height: 100vh;
	max-height: 400px;
	&>img{
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		z-index: -1;
	}
	.main-header{
		background-image: linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0) );
		padding: 20px 0px;
		height: 50%;
		.main-logo{
			&-link{
				display: inline-block;
			}
			max-width: 150px;
		}
	}
}