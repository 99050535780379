.request-account{
	position: relative;
	z-index: 1;
	margin-top: -150px;
	.heading{
		font-size: 36px;
		margin-bottom: 15px;
		font-weight: 700;
	}
	.card{
		padding: 35px 45px;
		border-radius: 20px;
		box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.10) ;
	}
	.border-right{
		border-right: 1px solid #e0e0e0;
	}
	.form-section{
		padding: 55px;
		padding-left: 30px;
	}
	.document-section{
		padding: 55px;
		padding-right: 30px;

		h5{
			font-size: 16px;
			color: #515B52;
		}
		ul{
			margin-top: 35px;
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			li{
				display: flex;
				justify-content:flex-start;
				flex-wrap: wrap;
				margin-bottom: 20px;
				font-size: 18px;
				&:last-of-type{
					margin-bottom: 0;
				}
				.box{
					flex: 0 0 24px;
					width: 24px;
					height: 24px;
					border-radius: 6px;
					margin-right: 15px;
					background-color: var(--bs-primary);
					color: #ffffff;
					display:flex;
					align-items:center;
					justify-content:center;
					svg{
						width: 20px;
						height:20px;
						margin-top: 1px;
					}
				}
				p{
					flex: 1 1 ;
					margin-bottom: 0;
				}

			}
		}
	}
}