.nav{
	&-tabs{
		border-bottom: 1px solid #dee2e6;
		margin-bottom: 0;
		.nav-item{
			.nav{
				&-link{
					color: $black-00;
					margin-bottom: -1px;
					font-size: 16px;
					border-width: 1px 1px 0px 1px;
					border-style: solid;
					border-color: transparent;
					&:hover{
						color: var(--bs-primary);
					}
					&.active{
					    background-color: #ffffff;
					    box-shadow: none;
					    color: var(--bs-primary);
					    border-width: 1px 1px 0px 1px;
					    border-radius: 0;
					    border-style: solid;
					    border-color: #dee2e6;
					}
				}
			}
		}
	}
}
.tab{
	&-content{
        padding: 20px;
	    color: #000;
	    border-width: 0px 1px 1px 1px;
	    border-style: solid;
	    border-color: #dee2e6;
	    font-size: 16px;
	}
}