.table{
	&-responsive{
		border-bottom: none !important;
		.dataTables{
			&_wrapper{
				.row{
					.dataTables{
						&_length{
							padding-left: 20px;
						}
					}
					.dataTables{
						&_filter{
							padding-right: 20px;
						}
					}
			    }
			    table{
			    	tr{
			    		&.even,&.odd{
			    			td{
			    				color: #666666;
			    			}
			    		}
			    	}
			    }
		    }
		}
	}
	&-inner{
		@media screen and (min-width:1250px) and (max-width:1440px) {

			th{
				font-size: 14px;
				padding: 10px !important;
			}
			td{
				font-size: 12px !important;
				padding: 15px !important;
				font-weight: 400 !important;
			}
			.dataTables_length{
				select{
					font-size: 14px !important;
					padding: 10px !important;
				}
			}
			.dataTables_info{
				font-size: 14px !important;
				display: flex;
				align-items: center;
				height: 100%;
			}
			.pagination{
				li{
					font-size: 12px !important;
				}
			}
		}
		
	}
	&.dataTable{
		margin-bottom: 0px !important;
		thead{
			tr{
              th{
              	background-color: #1d721e;
              	padding: 16px 20px;
              	font-weight: bold;
              	color: #ffffff;
              	&:first-child,&:last-child{
              		border-radius: 0;
              	}
              }
			}
		}
		tbody{
			tr{
			  &.even{
			  	background-color: #f9f9f9;
			  }
			   td{
                 padding:12px 20px;
                 border: none;
                 	.btn{
                 		margin-left: 5px;
                 		padding: 0;
                 		&-delete{
                 			color: #c52323;
                 		}
                 		&-edit{
                 			color: #d59c18;
                 		}
                 		&-list{
                 			color: #1d721e;
                 		}
                 		&-add{
                 			color: #f16a1b;
                 		}
                 	}
                 .badge{
                 	color: #1d721e;
                 	font-size: 15px;
                 	font-weight: 300;
                 }
			   }
			}
		}
	}
}
