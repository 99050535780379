.dataTables{
	&_length{
		select{
			margin: 0px 5px;
		}
	}
	&_wrapper{
		table{
			margin-top: 0;
			thead{
				tr{
					th{
						background-color: rgba(var(--bs-primary-rgb), 0.10);
						color: $black-00;
						text-transform: unset;
						&:first-child{
							border-radius: 5px 0 0 5px;
						}
						&:last-child{
							border-radius: 0px 5px 5px 0px;
						}
					}
				}
			}
			tbody{
				tr{
					&.even, 
					&.odd{
						td{
							color: #4f4f4f;
							font-size: 15px;
							font-weight: 300;
						}
					}
				}
			}
			thead,tbody{
				tr{
					background-color: transparent;
					th,td{
						padding: 10px;
						box-shadow: unset !important;
					}
				}
			}
		}
	}
	
	
} 