@use 'sass:math';

.card {
	border-radius: 10px;
	$padding: 30px;
	&-header & {
		&-title,
		h4 {
			font-size: 20px;
			font-weight: 500;
		}
	}

	&-header {
		padding: $padding;
	}

	&-header+&-body {
		padding-top: math.div($padding, 1.75);
	}

	&-body {
		padding: $padding;
	}

	&-border {
		padding: 10px;
		border: 1px solid #ddd;
		border-radius: 10px;
		margin-bottom: 15px;
	}
	&-height{
		min-height: calc(100% - 32px);
	}

	&-xs {
		padding: 0;

		.card-body {
			padding: 15px;
		}
	}

}