.btn{
	border-radius: 50px;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.3px;
    font-family: $font;
    display: inline-flex;
    align-items: center;
    justify-content: center;
	svg {
		display: block;
	}
	&.btn{
		&-xs{
			min-height: 36px;
			padding-left: 16px;
			padding-right: 16px;
			font-size: 15px;
		}
	}


	&.btn {
		&-grey{
			background: #e0e0e0;
			border-color: #e0e0e0;
			color: #454545;

			&:hover {
				background: darken(#e0e0e0, 10%);
				border-color: darken(#e0e0e0, 10%);
				color: #000;
			}
		}
	}
	&.btn {
		&-black{
			background: $black-00;
			border-color: $black-00;
			color: #ffffff;
			&:hover{
				background: #242424;
				border-color: #242424;
			}
		}
	}
	&-icon{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		$icon-size: 18px;
		
		.btn-inner svg,
		svg{
			position: unset;
			width: $icon-size;
			height: $icon-size;
		}
		&.btn{
			&-sm{
				width: 32px;
				height: 32px;
			}
		}
	}
}