body{
	$sr-width: 6px;
	.scrollbar{
		&-track{

			&-x{
				width: $sr-width;
			}
			&-y{
				width: $sr-width;
			}
			background: rgba(var(--bs-primary-rgb),0.10);
			&.show{

			}
		}
		&-thumb{
			width: $sr-width;
			background: rgba(var(--bs-primary-rgb),0.45);
		}
	}
}