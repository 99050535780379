.sign-bg{
	overflow: hidden;
	z-index: 0;
	img{
		opacity: 0.25;
		margin-top: -100px;
		margin-left: -100px;;

	}
}
.login-content{
	.navbar-brand{
		margin-bottom: 75px;
		justify-content: center;
		img{
			max-width: 150px;
			width: 100%;
		}
	}
	.card-transparent{
		background: transparent;
	}
}